import Web3 from "web3";
import { tokenContractAbi } from "./TokenContractAbi";

export const RPC_detail = {
    chainId: "0xa33",
    chainName: "RLC-Mainet",
    rpcUrls: [""],
    blockExplorerUrls: ["https://dataseed2.redlightscan.finance/"],
    nativeCurrency: {
      symbol: 'RLC',
      decimals: 18
    }
   
  };
  // export const ChainIds = 97
  export const ChainIds = 11155111

  
  export const getTokenBalance = (address, walletaddress) => {
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(tokenContractAbi, address);
    return new Promise((resolve, reject) => {
      contract.methods
        .balanceOf(walletaddress)
        .call()
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

 
  export const projectId = 'db374e4debdaa59545c0b24f71f868a8';




export function formatAmount(amount) {
  if (amount === undefined || amount === null) {
        return ''; 
    }
  // Convert amount to string
  let amountString = amount.toString();
    
  // Separate the integer part and the fractional part
  let parts = amountString.split('.');
  let integerPart = parts[0];
  let fractionalPart = parts[1] || '';

  // Limit the integer part to 9 digits
  integerPart = integerPart.substring(0, 9);

  // Check if fractional part is non-zero
  if (fractionalPart.length > 0 && parseInt(fractionalPart) !== 0) {
      // Limit the fractional part to 3 digits
      fractionalPart = fractionalPart.substring(0, 3);
      // Pad fractional part with zeros if it has fewer than 3 digits
      fractionalPart = fractionalPart.padEnd(3, '0');
      // Include fractional part only if it's non-zero
      return `${integerPart}.${fractionalPart}`;
  } else {
      // If fractional part is zero or empty, return integer part only
      return integerPart;
  }
}
