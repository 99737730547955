import axios from "axios";
import { ChainId, RPC_detail, chainId } from "../Web3/Constant";

let token = sessionStorage.getItem("JsonWebToken");
 let baseURL="https://onecm-dapp-be.onecannabismarketinc.com"
//  https://onecm-coin-dapp-be.onecannabismarketinc.com
// let baseURL = "http://103.125.70.205:8648";
const setAuthorizationToken = () => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

const instance = axios.create({
  baseURL:baseURL,
});
instance.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] =`Bearer ${sessionStorage.getItem("JsonWebToken")}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default setAuthorizationToken;
export {instance}

export const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

export const passwordRegex = {
  minLength: 8,
  specialChar: /[!@#$%^&*(),.?":{}|<>]/,
  number: /\d/,
  capital: /[A-Z]/,
  lower: /[a-z]/,
};
export const myRegex = /^(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).{8,}$/;
export const JSON_RPC_URL = {
  // 1: ['https://dataseed2.redlightscan.finance/'],
  // 3: ['https://dataseed2.redlightscan.finance/']
  1: ['https://sepolia.infura.io/v3/bc2c993ed59b4300bd593943286d1c76'],
  3: ['https://ropsten.infura.io/v3/bc2c993ed59b4300bd593943286d1c76']
}
export const theme = {
  primary: '#000',
  secondary: '#666',
  interactive: '#AC7AF7',
  container: '#FFF',
  module: '#E7E7E7',
  accent: '#AC7AF7',
  outline: '#AC7AF7',
  dialog: '#FFF',
  fontFamily: 'Raleway, sans-serif',
  borderRadius: 0.8,
}
export const wallletaddressregex=/^0x[0-9a-fA-F]{40}$/;
// export const tokenContractAddress = "0x3121846D446C67879aF2C95D85DDdE44426ff130";
export const tokenContractAddress = "0x3490E1Ac121a024754fa1661e08845e8F67243D0";

export const masterwallect = "0xd2c1d1133FC81C1179349B0dE0FfEf4E72F69848";



export const cardTypeImages = {
  visa: "https://logowik.com/content/uploads/images/visa-payment-card1873.jpg",
  // vise:vise,
  mastercard: 'https://lwcdn.freebitco.in/wp-content/uploads/2023/07/Mastercard-img.png',
  amex: 'https://as2.ftcdn.net/v2/jpg/03/07/14/09/1000_F_307140983_MDNd4Mtv5qgd3LAUK40ru1EPyYWL4elG.jpg',
  discover: 'https://yt3.googleusercontent.com/ytc/AIf8zZSmZwIYzTVMMsZggpKALnzzyS28aowwtRed5_qjOQ=s900-c-k-c0x00ffffff-no-rj',
  diners: 'https://as2.ftcdn.net/v2/jpg/03/07/14/09/1000_F_307140983_MDNd4Mtv5qgd3LAUK40ru1EPyYWL4elG.jpg',
  jcb: 'https://as2.ftcdn.net/v2/jpg/03/07/14/09/1000_F_307140983_MDNd4Mtv5qgd3LAUK40ru1EPyYWL4elG.jpg',
  unionpay: 'https://as2.ftcdn.net/v2/jpg/03/07/14/09/1000_F_307140983_MDNd4Mtv5qgd3LAUK40ru1EPyYWL4elG.jpg',
  maestro: 'https://as2.ftcdn.net/v2/jpg/03/07/14/09/1000_F_307140983_MDNd4Mtv5qgd3LAUK40ru1EPyYWL4elG.jpg',
  mir: 'https://as2.ftcdn.net/v2/jpg/03/07/14/09/1000_F_307140983_MDNd4Mtv5qgd3LAUK40ru1EPyYWL4elG.jpg',
  elo: 'https://as2.ftcdn.net/v2/jpg/03/07/14/09/1000_F_307140983_MDNd4Mtv5qgd3LAUK40ru1EPyYWL4elG.jpg',
  hiper: 'https://as2.ftcdn.net/v2/jpg/03/07/14/09/1000_F_307140983_MDNd4Mtv5qgd3LAUK40ru1EPyYWL4elG.jpg',
  hipercard: 'https://as2.ftcdn.net/v2/jpg/03/07/14/09/1000_F_307140983_MDNd4Mtv5qgd3LAUK40ru1EPyYWL4elG.jpg',
};

export const clientId = "f42251107d7ce9c2c1147db0c4f041c8";
 export const clientSecret = "addacbc76a541f79e77ac6390dd4f1a3";
 export const customChain = {
  // Required information for connecting to the network
  chainId: 11155111, // Chain ID of the network
  rpc: ["https://drpc.org/chainlist/sepolia"], 
 
  // Information for adding the network to your wallet (how it will appear for first time users) === \\
  explorers:  [{
     name: "Sepolia-testnet",
     url: "https://sepolia.etherscan.io/",
     standard: "EIP3091",
}],
  nativeCurrency: {
    decimals: 18,
    name: "Sepolia Testnet",
    symbol: "ETH",
    url: "https://sepolia.etherscan.io/"
  },
  shortName: "czkevm", // Display value shown in the wallet UI
  slug: "Sepolia Testnet", // Display value shown in the wallet UI
  testnet: true, // Boolean indicating whether the chain is a testnet or mainnet
  chain: "Sepolia Testnet ", // Name of the network
  name: "Sepolia Testnet ",
};
