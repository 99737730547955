import React, { useState } from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './styles/App.scss';
import { Route, Routes } from "react-router-dom";
import NavBar from './Components/NavBar';
import Login from './Components/Login';
import ForgotPassword from './Components/ForgotPassword'
import PurchaseGift from './Pages/PurchaseGift';
import CoinSwap from './Pages/CoinSwap';
import UniSwap from './Pages/UniSwap';
import CardDetails from './Pages/CardDetails';
import SendGiftCard from './Pages/SendGiftCard';
import PrivateRoutes from './Route/PrivateRoutes';
import ROUTES from './Route/RoutePath';
function NotFound() {
  return <div className='bg-gradient w-100 vh-100 d-flex justify-content-center align-items-center'><h1 className='text-white font-weight-bold'>404 - Page Not Found</h1></div>;
}
function Router() {
  const [showNavBar, setShowNavBar] = useState(true);
  return (
    <>
      {showNavBar && <NavBar />}

      <Routes>
        <Route path={ROUTES.MAIN_ROUTE} element={<Login setShowNavBar={setShowNavBar} />} />
        <Route exact path={ROUTES.LOGIN} element={<Login setShowNavBar={setShowNavBar} />} />
        {/* <Route exact path="/" element={<Login setShowNavBar={setShowNavBar} />}/> */}
        {/* <Route path="/ForgotPassword" element={<ForgotPassword setShowNavBar={setShowNavBar} />} /> */}


        <Route path="*" element={<NotFound />} />
        {/* this routes work with login only */}
        <Route element={<PrivateRoutes />}>
          <Route path="/UniSwap" element={<UniSwap />} />
          <Route path="/PurchaseGift" element={<PurchaseGift />} />
          <Route path="/CardDetails" element={<CardDetails />} />
          <Route path="/CoinSwap" element={<CoinSwap />} />
          <Route path="/SendGiftCard" element={<SendGiftCard />} />
        </Route>
      </Routes>

    </>
  );
}

export default Router;