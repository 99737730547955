import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Button, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import logo from '../Assets/image/small-logo.png';
import {  SwapWidget } from '@uniswap/widgets'
import '@uniswap/widgets/fonts.css'
import detectEthereumProvider from "@metamask/detect-provider";
import Web3 from "web3";
import { JSON_RPC_URL, theme } from '../Constant/Constant';
import { useAddress, useNetworkMismatch,  useNetwork, ChainId,} from '@thirdweb-dev/react';
function UniSwap() {
    const [providerMetaMask, setProviderMetaMask] = useState("")
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const address = useAddress();

    const isWrongBlockcahin = useNetworkMismatch();
    const [, switchNetwork] = useNetwork();

    const setupProvider = async () => {
        let provider = await detectEthereumProvider();
        setProviderMetaMask(provider);
        if (provider !== window.ethereum) {
            window.web3 = new Web3(provider);
        } else {
            window.web3 = new Web3(window.ethereum);
        }
    };

    const fetchData = async () => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        try {
            const response = await fetch("https://tokens.coingecko.com/uniswap/all.json", requestOptions);
            if (!response?.ok) {
                throw new Error(`Network response was not ok: ${response?.status}`);
            }
            const result = await response?.json();
            setLoading(false);
            setData(result?.tokens?.filter((item) => item?.symbol?.length >= 1));
        } catch (error) {
        }
    };
    useEffect(() => {
        setupProvider();
        fetchData();
        return () => {
        };
    }, []);



    return (
        <>
            <main className='inner-main'>
                <div className='full-div'>
                    <div className='card-panel'>
                    {!loading && (
                            <SwapWidget
                                provider={providerMetaMask}
                                theme={theme}
                                jsonRpcUrlMap={JSON_RPC_URL}
                                tokenList={data}
                                className='uniswap'
                            />
                    )}
                      

                    </div>
                  
                </div>
            </main>
        </>
    );
}
export default UniSwap; 