import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Dropdown, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faAngleDown, faCopy, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import setAuthorizationToken, { instance, masterwallect, tokenContractAddress, wallletaddressregex } from '../Constant/Constant';
import { useContextDetails } from '../Constant/AppContext';
import { NotificationTypes, showNotification } from '../Constant/Alert';
import { PulseLoader } from 'react-spinners';
import Web3 from 'web3';
import { tokenContractAbi } from '../Web3/TokenContractAbi';
import coin from '../Assets/image/coin-1.png';
import coin1 from '../Assets/image/coin-2.png';
import { ChainIds, formatAmount, getTokenBalance } from '../Web3/Constant';
import NavBar from '../Components/NavBar';
import { useAddress, useChainId } from '@thirdweb-dev/react';


function CoinSwap() {

    const [amount, setAmount] = useState("");
    const [paymentMethod, setPaymentMethod] = useState('Offline');
    // const [walletAddress, setWalletAddress] = useState();
    const [isWalletAddress, setisWalletAddress] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isSwap, setIsSwap] = useState(false);
    const [balance, setBalance] = useState();
    const [txHash, setTxHash] = useState(null);
    const [walletAddress, setWalletAddress] = useState(paymentMethod === 'Offline' ? '' : masterwallect);
    const [isUserAddressDisabled, setIsUserAddressDisabled] = useState(false);
    const [tokensBalance, settokensBalance] = useState("");
    const [disableButton, setDisableButton] = useState(false); 

    const [isCopied, setIsCopied] = useState(false);
    const contextData = useContextDetails();
    const address = useAddress();
    const chainId = useChainId();
    const getValidatorsfunction = (address) => {
        if (!address || !chainId || chainId !== ChainIds) {
            return;
        }
        getTokenBalance(tokenContractAddress, address)
            .then((fee) => {
                settokensBalance(fee / 10 ** 18)

            })
            .catch(() => {
                // setAmount('');
            });
    }
    useEffect(() => {
        if (!address || !chainId || chainId !== ChainIds) {
            setAmount('');
            settokensBalance('')
        }
        getValidatorsfunction(address);

    }, [address, chainId]);
    useEffect(() => {
        if (!tokensBalance) {
            setAmount('')
        }
    }, [tokensBalance])
    useEffect(() => {
        if (window.location.pathname === "/CoinSwap") {
            window.history.pushState(null, null, window.location.pathname);
            window.addEventListener("popstate", function () {
                window.history.pushState(null, null, window.location.pathname);
            });
        }
    }, [])

    useEffect(()=>{
        contextData?.setonlinebalace(tokensBalance);
    },[tokensBalance])

    const balancelimit = balance;


    const handlePaymentMethodChange = (selectedMethod) => {
        setPaymentMethod(selectedMethod);
        setAmount('');


        if (selectedMethod === 'Online') {
            setWalletAddress(masterwallect);
            setIsUserAddressDisabled(true);
            setAmount('');
            if (!address) {
                showNotification('Please connect your wallet', NotificationTypes.ERROR);
            }

        } else {
            setWalletAddress('');
            setIsUserAddressDisabled(false);
        }
    };

    const handleAmountChange = (event) => {
        let input = event.target.value?.trim();

        input = input.replace(/[^0-9.]/g, '');

        const [integerPart, decimalPart] = input.split('.');
        const isWithinLimit = !isNaN(input) && integerPart.length <= 9 && (!decimalPart || decimalPart.length <= 5);
        if (paymentMethod === 'Online') {
            if (tokensBalance === 0) {
                // showNotification('Token balance is zero', NotificationTypes.ERROR);
                return;
            }
            const isWithinTokenBalance = parseFloat(input) <= tokensBalance;

            if (!isWithinTokenBalance && input !== '') {
                // showNotification('Entered amount exceeds token balance', NotificationTypes.ERROR);
                return;
            }
        } else if (paymentMethod === 'Offline') {
            const isWithinOfflineBalanceLimit = parseFloat(input) <= balancelimit;

            if (!isWithinOfflineBalanceLimit && input !== '') {
                // showNotification('Entered amount exceeds offline balance limit', NotificationTypes.ERROR);
                return;
            }
        }

        if (isWithinLimit || input === '') {
            setAmount(input);
            setIsSwap(!!input.trim());

        } else {
            event.target.value = '';
        }
    };


    const handleChange = (event) => {
        let inputValue = event.target.value;

        if (!inputValue.startsWith("0x")) {
            inputValue = "" + inputValue.trim();
        }

        if (inputValue.length > 42) {
            inputValue = inputValue.slice(0, 42);
        }
        setWalletAddress(inputValue);
        const isValid = validateWalletAddress(inputValue);
        setisWalletAddress(isValid);


    };



    const validateWalletAddress = (address) => {
        const isValid = wallletaddressregex.test(address);
        return isValid;
    };

    const handleEnterAmount = (e) => {
        e.preventDefault();
        if (paymentMethod === 'Online' && !address) {
            showNotification('Please connect your MetaMask wallet', NotificationTypes.ERROR);
            setIsLoading(false);
            return;
        }
        if (paymentMethod === 'Online' && chainId != ChainIds) {
            showNotification('Please switch network to the correct chain ', NotificationTypes.ERROR);
            setIsLoading(false);
            return;
        }
        if (paymentMethod === 'Online' && !tokensBalance) {
            showNotification('Account balance insufficient', NotificationTypes.ERROR);
            setIsLoading(false);
            return;
        }


        if (!amount || !walletAddress) {
            showNotification('Please fill in all required fields', NotificationTypes.ERROR);
            return;
        }
        if (parseFloat(amount) === 0) {
            showNotification('Amount cannot be zero', NotificationTypes.ERROR);
            return;
        }

        if (paymentMethod === 'Offline' && !isWalletAddress) {
            showNotification('Invalid wallet address', NotificationTypes.ERROR);
            return;
        }

        setIsLoading(true);

        if (paymentMethod === 'Offline') {
            handleOfflineSwap();
        } else if (paymentMethod === 'Online') {
            handleOnlineSwap();
        }
    };
    let intervalId ;
    const updateBalance = () => {
        const delayedFunctionCall = () => {
            if (!address || !chainId || chainId !== ChainIds) {
                return;
            }
            getTokenBalance(tokenContractAddress, address)
                .then((fee) => {
                    let _balance = fee / 10 ** 18;
                    if (tokensBalance == _balance) {
                        return; 
                        
                    }
                    settokensBalance(_balance);
                   clearInterval(intervalId)
                })
                .catch(() => {
                });
        };
    
        const intervalTime = 2000;
        intervalId = setInterval(delayedFunctionCall, intervalTime);
    
        return intervalId;
    };

    const handleOfflineSwap = () => {
        if (paymentMethod === 'Offline') {
            // setIsLoading(true);
            setDisableButton(true);
        }        const requestData = {
            accountId: contextData?.userInfo?.accountId,
            offerredAmount: amount,
            userAddress: walletAddress,
        };
        // const formData = new FormData();
        // for (const key in requestData) {
        //     formData.append(key, requestData[key]);
        // }

        setAuthorizationToken();

        instance.post('api/Swap/OffRampSwap', requestData)
            .then((response) => {
                showNotification('You got your amount in a while', NotificationTypes.SUCCESS);
                updateBalance()
                setAmount('');
                getValidatorsfunction(address);
                setWalletAddress('');
                setIsSwap(false);
                fetchData();
            })
            .catch((error) => {
                showNotification(error?.response?.data?.Message, NotificationTypes.ERROR);
            })
            .finally(() => {
                setIsLoading(false);
                setTimeout(() => {
                    setDisableButton(false);
                }, 60000);
            });
    };

    const handleOnlineSwap = async () => {
        if (paymentMethod === 'Online' && !address) {
            showNotification('Please connect your  wallet', NotificationTypes.ERROR);
            setIsLoading(false);
            return;
        }
        if (!address || !tokensBalance) {
            showNotification('Account balance insufficient', NotificationTypes.ERROR);
            setIsLoading(false);
            setAmount('');
            return;
        }


        setIsLoading(true);
        try {
          
            const web3 = new Web3(window.ethereum);
            
            const tokenContract = new web3.eth.Contract(tokenContractAbi, tokenContractAddress);
            const amountInWei = web3.utils.toWei(amount, 'ether');

            const transationHash = await tokenContract.methods
                .transfer(walletAddress, amountInWei)
                .send({ from: address });
            setTxHash(transationHash?.transactionHash);
            sessionStorage.setItem("txHash", transationHash?.transactionHash);
            sessionStorage.setItem("offerredAmount", amount);
            sessionStorage.setItem("userAddress", address);
            const requestData = {
                txHash: transationHash?.transactionHash,
                userAddress: address,
                offerredAmount: amount,
            };

            setAuthorizationToken();
            await new Promise(resolve => setTimeout(resolve, 3000));
            await instance.post('api/Swap/OnRampSwap', requestData);
            sessionStorage.removeItem("txHash");
            sessionStorage.removeItem("offerredAmount");
            sessionStorage.removeItem("userAddress");
            showNotification('You got your amount in a while', NotificationTypes.SUCCESS);
            getValidatorsfunction(address);
            setAmount('');
            setIsSwap(false);
            fetchData();

        } catch (error) {
            if (error?.code === 4001) {
                showNotification('Transaction has been rejected', NotificationTypes.ERROR);
            } else {
                // Handle other errors
                console.error("Error interacting with MetaMask:", error);
                showNotification('An error occurred while processing the transaction', NotificationTypes.ERROR);
            }
            setAmount('');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const fetchDataFromSessionStorage = async () => {
            const storedTxHash = sessionStorage.getItem("txHash");
            const storedOfferedAmount = sessionStorage.getItem("offerredAmount");
            const storedUserAddress = sessionStorage.getItem("userAddress");
    
            if (storedTxHash && storedOfferedAmount && storedUserAddress) {
                try {
                    setIsLoading(true);
    
                    const requestData = {
                        txHash: storedTxHash,
                        offerredAmount: storedOfferedAmount,
                        userAddress: storedUserAddress,
                    };
    
                    await instance.post('api/Swap/OnRampSwap', requestData);
                    sessionStorage.removeItem("txHash");
                    sessionStorage.removeItem("offerredAmount");
                    sessionStorage.removeItem("userAddress");
    
                    showNotification('You got your amount in a while', NotificationTypes.SUCCESS);
                    setAmount('');
                    setIsSwap(false);
                    fetchData();
                    getValidatorsfunction(address);
                } catch (error) {
                    sessionStorage.removeItem("txHash");
                    sessionStorage.removeItem("offerredAmount");
                    sessionStorage.removeItem("userAddress");
                    setAmount('');
                } finally {
                    setIsLoading(false);
                }
            } else {
                // console.log('Required information not found in session storage');
            }
        };
    
        fetchDataFromSessionStorage();
    }, []);
    

    const fetchData = () => {
        setAuthorizationToken();
        instance.get('/api/Admin/GetWalletBalance')
            .then((response) => {
                const parsedBalance = parseFloat(response?.data?.data);
                const roundedBalance = Math.round(parsedBalance);
                contextData?.setIsBalance(roundedBalance)
                setBalance(roundedBalance)
            })
            .catch((error) => {
                // Handle API errors here.
            });
    };

    useEffect(() => {
        fetchData();
    }, []);

    const copyToClipboard = async () => {
        try {
          await navigator.clipboard.writeText(tokenContractAddress);
          
          setIsCopied(true);
          setTimeout(() => {
            setIsCopied(false);
        }, 2000);
        } catch (error) {
          showNotification("Failed to copy to clipboard", NotificationTypes.ERROR);
        }
      };
      const PaymentMethod = {
        Online: 'Online',
        Offline: 'Offline'
    };
    

    return (
        <>
            {/* <NavBar  setAmount={setAmount}/> */}
            <main className='inner-main'>
                <div className='full-div'>
                    <div className='card-panel'>
                        <div className='flex-div'>
                            <h6 className='m-0'>Swap</h6>
                            <Dropdown>
                                <Dropdown.Toggle id='dropdown-basic'>
                                    {paymentMethod} <FontAwesomeIcon icon={faAngleDown} />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {paymentMethod === 'Online' ? (
                                        <Dropdown.Item onClick={() => handlePaymentMethodChange('Offline')}>
                                            Offline
                                        </Dropdown.Item>
                                    ) : (
                                        <Dropdown.Item onClick={() => handlePaymentMethodChange('Online')}>
                                            Online
                                        </Dropdown.Item>
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div className='spacer-20'></div>
                        <div className='your-amount-pnl'>
                            <Form>
                                <div className='amount-pnl'>
                                    <p>User Address</p>
                                    <input
                                        type="text"
                                        className='walletaddress'
                                        name="text"
                                        value={walletAddress}
                                        placeholder="Enter wallet address"
                                        onChange={handleChange}
                                        isInvalid={!isWalletAddress}
                                        disabled={isUserAddressDisabled}
                                        autoComplete='off'

                                    />
                                    {paymentMethod === 'Offline' && !isWalletAddress && walletAddress && (
                                        <div className='invalidwallet'>Invalid wallet address</div>
                                    )}


                                </div>
                                <div className='amount-pnl'>
                                <div className='flex-divtext'>
                                    <p>
                                        
        You Pay {paymentMethod ===PaymentMethod?.Online? PaymentMethod?.Online :PaymentMethod?.Offline}
        {paymentMethod === PaymentMethod?.Online&&address && chainId == ChainIds && (
            <span onClick={copyToClipboard} className='copybtn'>
            {isCopied ? (
                    <>
                        <FontAwesomeIcon icon={faCheckCircle} className='copytext' />
                        <span className='textstyle'>Copied</span>
                    </>
                ) : (
                    <FontAwesomeIcon icon={faCopy} />
                )}
            </span>
        )}
    </p>
    <div className='small-img-pnl'>
            <img src={coin1} alt="coin" />
        </div>
        </div>

                                    <div className='flex-div'>
                                        <input
                                            type='text'
                                            value={amount}
                                            onChange={handleAmountChange}
                                            className='inputfiled'
                                            placeholder='0'
                                            autoComplete='off'
                                            onPaste={(e) => e.preventDefault()}

                                        />


                                        <div className='small-img-pnl'>
                                            <span>
                                                {formatAmount(paymentMethod === 'Online' ? tokensBalance : balance)}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className='amount-pnl'>
                                <div className='flex-divtext'>
                                <p>You Receive {paymentMethod === PaymentMethod?.Online ? PaymentMethod?.Offline : PaymentMethod?.Online}
                                    {paymentMethod === PaymentMethod?.Offline &&address && chainId == ChainIds && (
            <span onClick={copyToClipboard} className='copybtn'>
            {isCopied ? (
                    <>
                        <FontAwesomeIcon icon={faCheckCircle} className='copytext' />
                        <span className='textstyle'>Copied</span>
                    </>
                ) : (
                    <FontAwesomeIcon icon={faCopy} />
                )}
            </span>
        )}
                                    </p>
                                    <div className='small-img-pnl'>
            <img src={coin1} alt="coin" />
          
        </div>
    </div>
    <div className='flex-div'>
                                    <input
                                            type='text'
                                            value={amount}
                                            className='inputfiledamount'
                                            placeholder='0'
                                            autoComplete='off'
                                            onPaste={(e) => e.preventDefault()}

                                        />
                                        <div className='small-img-pnl'>
                                          
                                        <span>
                {formatAmount(paymentMethod === 'Online' ? balance : tokensBalance)}
            </span>
                                        </div>
                                    </div>
                                        
                                </div>
                                <Button type='submit' className='grey-btn' onClick={handleEnterAmount} disabled={isLoading  || (paymentMethod === 'Offline' && disableButton)}>

                                    {isLoading ? (
                                        <PulseLoader size={10} color="#fff" />
                                    ) : isSwap ? (
                                        'Swap'
                                    ) : (
                                        'Enter an amount'
                                    )}
                                </Button>
                            </Form>

                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

export default CoinSwap;